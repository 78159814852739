<template>
  <div id="app">
    <html lang="en">

    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Document</title>
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.2/css/all.css"
        integrity="sha384-vSIIfh2YWi9wW0r9iZe7RJPrKwp6bG+s9QZMoITbCckVJqGCCRhc+ccxNcdpHuYu" crossorigin="anonymous">
    </head>

    <body>
      <section>
        <h1>Login</h1>
        <div class="login-form">
          <h4>Email</h4>
          <div class="username-input">
            <i class="fas fa-user"></i>
            <input type="email" v-model="email">
          </div>
          <h4>Password</h4>
          <div class="password-input">
            <i class="fas fa-lock"></i>
            <input type="password" v-model="password">
          </div>
          <p>Forgot password?</p>
        </div>
        <button class="login-btn" @click="login">
          LOGIN
        </button>
        <div class="alternative-signup">
          <p>Not a member? <span>Sign-up</span></p>
        </div>
      </section>
    </body>

    </html>
  </div>
</template>
 
<script>
import API from "@/js/api";
import store from "@/store";
export default {
  store,
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
    };
  },
  /*mounted: async function () {
    var self = this;
    await self.$store.dispatch("auth/getUser");
  },*/
  beforeMount() {
    console.log("user", this.isAuthenticated);
    this.handleRedirection();
  },
  methods: {
    async handleRedirection() {
      let isAuthenticated = store.getters["auth/isAuthenticated"];
      if (isAuthenticated) {
        var redirectTo = this.$route.query.redirect;
        if (redirectTo) {
          this.$router.push(redirectTo);
        } else {
          this.$router.push("/");
        }
      }
    },
    //Log in with the form's email/username and password
    async login() {
      var data = {
        email: this.email,
        password: this.password,
      };
      if (data.email && data.password) {
        var response = await API.login(data);
        console.log(response);
        if (response.user && response.user.isAuthenticated) {
          var redirectTo = this.$route.query.redirect;
          this.$cookies.set("SID", response.cookie, "1d");
          await this.$store.dispatch("auth/setUser", { status: true });
          if (redirectTo) {
            this.$router.push(redirectTo);
          } else {
            this.$router.push("/");
          }
        }
      }
      else {
        this.errorMessage = "Check and enter valid credentials.";
      }
    },
  },
};
</script>
  
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Condensed:wght@700&family=Roboto:wght@300;400;500;600;700&display=swap');


* {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

body {
  background-color: #fff9f9;
  min-height: 700px;
  min-width: 700px;
  height: 100vh;
  width: 100vw;
  background-color: rgb(95, 95, 95);
  background-image:
    radial-gradient(at 80% 0%, rgb(0, 0, 0) 0px, transparent 50%),
    radial-gradient(at 0% 50%, rgb(138, 138, 138) 0px, transparent 50%),
    radial-gradient(at 80% 50%, rgb(0, 0, 0) 0px, transparent 50%),
    radial-gradient(at 0% 100%, rgb(124, 115, 133) 0px, transparent 50%),
    radial-gradient(at 0% 0%, rgb(0, 0, 0) 0px, transparent 50%);
}

section {
  margin: 0 auto;
  width: 400px;
  height: 380px;
  transform: translateY(40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  box-shadow: 0px 0 31px 0px rgb(0 0 0 / 10%);
}

h1 {
  margin: 20px 0 10px 0;
  font-size: 3rem;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.login-form {
  width: 70%;
  margin-bottom: 15px;
}

h4 {
  margin: 20px 0 5px 0;
  font-size: 1.5rem;
  font-weight: 300;
}

input {
  color: #fff9f9;
  width: 80%;
  font-size: 1.1rem;
  font-weight: 300;
  padding: 7px 0;
  border: none;
  background-color: inherit;
  border: 0;
  outline: 0;
}

.username-input,
.password-input {
  border-bottom: 1px solid #000000;
  width: 90%;
}

i {
  width: 10%;
  color: rgba(0, 0, 0, 0.3);
  padding-right: 7px;
}

.login-form>p {
  width: 90%;
  font-size: 1.1rem;
  text-align: right;
  margin-top: 5px;
  font-weight: 300;
}

.login-btn {
  border: none;
  padding: 7px 20px;
  width: 50%;
  border-radius: 10px;
  font-size: 1.2rem;
  background-image: linear-gradient(43deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  color: white;
  font-weight: 600;
}

.login-btn:hover {
  background-image: linear-gradient(43deg, rgb(93, 93, 93) 0rgb(97, 97, 97) 100%);
}

.alternative-signup {
  width: 70%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alternative-signup>p {
  width: 90%;
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 300;
}

span {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(255, 255, 255);
}
</style>