<template>
	<div>
		<h2>Hello {{ user.name }}, Welcome to</h2>
		<h2>Task manager</h2>
	</div>
</template>

<script>
export default {
	name: "Home",
	mixins: [],
	components: {

	},
	data: function () {
		return {
		};
	},
	computed: {
		isAuthenticated() {
			return this.$store.getters["auth/isAuthenticated"];
		},
		user() {
			return this.$store.getters["auth/user"];
		}
	},
	created() {
		
	},
	methods: {
		
	},
};
</script>